import React, { useEffect } from "react";
import Header from "./components/header/Header";
import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Footer from "./components/footer/footer";
import Main from "./components/main/main";
import Projects from "./components/projects/projects";
import About from "./components/about/about";
// import aboutPortait from "./components/about/aboutPortrait";
import MyWebsite from "./components/projectpages/mywebsite";
import ItunesGallery from "./components/projectpages/itunesgallery";
import CryptoMonitor from "./components/projectpages/cryptomonitor";
import Experience from "./components/experience/experience";
import ReactGa from "react-ga";
import GameOfFifteen from "./components/projectpages/gameoffifteen";

function App() {
  useEffect(() => {
    const sendAnalytics = async () => {
      await ReactGa.initialize("UA-232385436-5");
      ReactGa.pageview(window.location.pathname);
    };
    sendAnalytics();
  });
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Main />} />
            <Route exact path="/projects" element={<Projects />} />
            <Route path="/projects/gameof15" element={<GameOfFifteen />} />
            <Route path="/projects/mywebsite" element={<MyWebsite />} />
            <Route path="/projects/itunesgallery" element={<ItunesGallery />} />
            <Route path="/projects/cryptomonitor" element={<CryptoMonitor />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/experience" element={<Experience />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
