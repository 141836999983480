import React from "react";
import ProjectPage from "./projectpage";

class CryptoMonitor extends React.Component {
  code = `const getOptions = async () => {
    try {
      const config = {
        method: "GET",
        url: "https://api.coingecko.com/api/v3/coins",
      };
      const { data } = await axios(config);
      let id = data.map((el) => el.id);
      console.log(id)
    } catch (error) {
      console.error(error);
    }
  };`;

  render() {
    return (
      <div>
        <ProjectPage
          name="Cryptocurrency Monitor"
          overview="my Crypto Monitor"
          description="This project was something I developed to keep track of the rapidly changing prices of cryptocurrency in 2021. To complete this project, I had to learn how to get information from the CoinGecko API and display it in a user-friendly manner. I also integrated the Discord webhook system to send the price updates to my personal server in an embedded format."
          description2="The video below is a quick demonstration of this project!"
          vid="/videos/CryptoMonitor.mp4"
          img="/images/webhook.png"
          codeBlock={this.code}
          description5="This monitor uses the Axios HTTP library to retrieve information from the CoinGecko API and display it in an embedded format while simultaneously sending a webhook to Discord with the information."
          description6="One of the challenges I faced with this project was retrieving the data from an API. I looked around for publicly available endpoints to pull Cryptocurrency information from but ultimately ended up choosing the CoinGecko API as it had all the information I wanted to display and it was all publicly available meaning I didn't need to request an API key. Once I chose an API, I had to send a GET request to the API and then parse through the array of objects that was returned to get the information that I wanted to display. "
          afterImgDesc="The code above is a small snippet of the call to the CoinGecko API where the different Crypto ID options are retrieved. Using the map function, I was able to map all the ID's found in the array of objects returned from the API call to an empty array which is then displayed to the user. The user can then pick one of the crypto ID's and pass it into the monitor. "
        />
      </div>
    );
  }
}

export default CryptoMonitor;
