import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./projectpage.css";
import { gsap, Power4 } from "gsap";
import Blocks from "../codeBlocks/blocks";

function ProjectPage(props) {
  const tl = gsap.timeline();

  useEffect(() => {
    tl.from(".mini-container", {
      y: "-30%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
    });
    tl.from(".project-content", {
      y: "30%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
    });
  });
  return (
    <div className="project-container">
      <div className="mini-container">
        <div className="header-container">
          <h1 className="header-name">{props.name}</h1>
          <Link className="projects-link" to="/projects">
            /projects
          </Link>
        </div>
        <h4> An overview of {props.overview}.</h4>
      </div>

      <div className="project-content">
        <h2>What is this?</h2>
        <p>{props.description}</p>
        {props.description2 ? <p>{props.description2}</p> : ""}
        {props.description3 ? <p>{props.description3}</p> : ""}
        {props.description4 ? <p>{props.description4}</p> : ""}
        {props.vid ? (
          <video
            className="project-video"
            src={props.vid}
            width="100%"
            height="auto"
            autoPlay="true"
            muted="true"
            controls="true"
          ></video>
        ) : (
          ""
        )}
        {props.img ? (
          <img className="project-image" alt="project" src={props.img} />
        ) : (
          ""
        )}
        <h2>How does it work?</h2>
        {props.description5 ? <p>{props.description5}</p> : ""}
        {props.description6 ? <p>{props.description6}</p> : ""}
        {props.description7 ? <p>{props.description7}</p> : ""}
        {props.description8 ? <p>{props.description8}</p> : ""}
        {props.codeBlock ? <Blocks code={props.codeBlock} /> : ""}
        {props.afterImgDesc ? <p>{props.afterImgDesc}</p> : ""}
      </div>
    </div>
  );
}

export default ProjectPage;
