import React from "react";
import ProjectPage from "./projectpage";

function MyWebsite() {

  return (
    <div>
      <ProjectPage
        name="My Website"
        overview="this website"
        description="This website is my personal website that I made to get a better understanding of HTML, CSS and JavaScript. The development phase of this project was segmented into three different versions with each version becoming more advanced as my understanding for these concepts increased."
        description6="V1: Version 1 was my first time working with HTML and CSS. I watched a crash course about the two and made the initial design of the website and experimented with different layouts to further my understanding. I made a lot of mistakes with the first version and decided to re-work the website to have a better HTML hierarchy so I could expand onto it in the future. After the re-work was finished, I started working on the CSS and upon completion, ultimately decided that it would be too difficult to follow through with the current design. I decided to focus on a simplistic design that will make it easier for me to expand upon in the future. "
        description7="V2: Version 2 was the first design with the simplistic black and white colorway with shades of blue. I wanted to incorporate more JavaScript in this version and made features that would greet the user differently based on the time of day and added animations upon load using the GSAP library. I was satisfied with the look of the website and knew it would be way easier to expand upon in the future. I asked my friend about what he thought of the site and he told me to look into React.js as it will make the site faster and even easier to expand upon in the future."
        description8="V3: Version 3 is the version you are currently looking at. Fully ported to React, this was a great way to learn a lot about the React library along with mastering HTML and CSS. I tried my best to divide all the parts of my website into reusable components which I can just pass properties into to change the component's purpose. This in-turn makes the website ten times easier to work with and creates a more seamless experience for visitors. Though I still have more to learn about Frontend Development, this website was an awesome way to learn more about these tools. "
      />
    </div>
  );
}

export default MyWebsite;
