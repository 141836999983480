import React from "react";
import ProjectPage from "./projectpage";

class GameOfFifteen extends React.Component {
  code = `/**
  * If tile borders empty space, moves tile and returns true, else
  * returns false.
  */
 int move(int tile)
 {
     // checks if the tile is a valid input
     int grid = d*d;
     if (tile > grid - 1 || tile < 1) {
         // returns 0 for false
         return 0;
     } // if
     // this finds the tile the empty slot is on.
     int r = 0;
     int c = 0;
     for (int i = 0; i < d; i++) {
         for (int j = 0; j < d; j++) {
             if (board[i][j] == tile) {
                 // r = row
                 // c = col
                 r = i;
                 c = j;
             } // if
         } // for
     } // for
     // checking if the slot is eligible to be moved.
     // if it is swap it and return 1.
     if (board[r][c + 1] == 0 && c + 1 < d) {
         board[r][c + 1] = board[r][c];
         board[r][c] = 0;
         return 1;
     } else if (board[r][c - 1] == 0 && c - 1 >= 0) {
         board[r][c - 1] = board[r][c];
         board[r][c] = 0;
         return 1;
     } else if (board[r + 1][c] == 0 && r + 1 < d) {
         board[r + 1][c] = board[r][c];
         board[r][c] = 0;
         return 1;
     } else if (board[r - 1][c] == 0 && r - 1 >= 0) {
         board[r - 1][c] = board[r][c];
         board[r][c] = 0;
         return 1;
     }
     return 0;
 }`;
  render() {
    return (
      <div>
        <ProjectPage
          name="Game of Fifteen"
          overview="my Game of Fifteen project"
          description="This was my first project in C. To complete this project, I had to learn how to think of the logic required to implement a text based game along with how to handle the different edge cases."
          description2="The video below is a quick demonstration of this project!"
          vid="/videos/gameof15demo.mp4"
          codeBlock={this.code}
          afterImgDesc="The code above is the move method which uses simple arithmetic and array indexing to determine whether the tile being moved is adjacent to the empty space and whether the move would result in a valid board configuration."
          description5="The game logic used to implement the game consists of different algorithms that generate and shuffle the initial configuration of the board, checks whether a move is valid and checks whether the board is in the correct order. If the board is in the correct order, the player will win the game and the program will exit."
        />
      </div>
    );
  }
}
export default GameOfFifteen;
