import React from "react";
import "./card.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Card(props) {
  return (
    <div className="card">
      <div className="name-date">
        <h2>{props.name}</h2>
        <h6>{props.date ? props.date : ""}</h6>
      </div>
      <h6 className="tools">{props.tools ? props.tools : ""}</h6>
      <p>{props.description ? props.description : ""}</p>
      <div
        className="learn-more-container"
        whileHover={{
          scale: 1.2,
          originX: 1,
        }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <Link className="learn-more" to={`/projects${props.url}`}>
          learn more
        </Link>
      </div>
    </div>
  );
}

export default Card;
