import React, { useEffect } from "react";
import ECard from "./ecard";
import "./experience.css";
import { gsap, Power4 } from "gsap";

function Experience() {
  const tl = gsap.timeline();

  useEffect(() => {
    tl.from(".head-container", {
      y: "-30%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
    });
    tl.from(".work-container", {
      y: "-30%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
    });
    tl.from(".volunteer", {
      y: "30%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
    });
    tl.from(".volunteer-container", {
      y: "30%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
    });
  });

  return (
    <div className="experience-container">
      <div className="head-container">
        <h1 className="header-name">My Experience</h1>
        <h4>My professional experience. </h4>
      </div>
      <div className="work-container">
        <ul className="experiences">
          <li className="w-experience-5">
            <ECard
              name="Workday"
              location="Atlanta, Georgia"
              position="Software Application Development Engineer Intern"
              time="May 2024 - Present"
              description="Working on federal integrations and enhancements."
            />
          </li>
          <li className="w-experience-4">
            <ECard
              name="GT College of Computing"
              location="Atlanta, Georgia"
              position="Undergraduate Teaching Assistant"
              time="January 2024 - Present"
              description="Teacher Assistant for CS 2340: Objects and Design (focus on app development in Android Studio and Git demo)."
              description2="Attend office hours to assist students with any concerns regarding Android Studio, Git, and SQLite."
              description3="Implement Agile workflow principles and MVVM (Model-View-ViewModel) pattern to ensure proper project deployment."
            />
          </li>
          <li className="w-experience-3">
            <ECard
              name="State Farm"
              location="Atlanta, Georgia"
              position="Software Engineering Intern"
              time="May 2023 - August 2023"
              description="Engineered a customer-facing modal using TypeScript and Angular.js to detect and mitigate issues in outdated versions of
              the claims hub, resulting in a 65% reduction in errors due to browser cache or incompatible versions."
              description2="Delivered an end-to-end project using the Mock Service Worker library to mock responses from all Claims Hub services,
              streamlining development and testing processes without affecting end-users."
              description3="Implemented a new Continuous Integration (CI) build job for the project, automating the generation of a static GitLab page with
              real-time updates to the latest production build, enhancing the productivity of 10+ UI/UX teams by 40%."
            />
          </li>
          <li className="w-experience-2">
            <ECard
              name="Yvngsneakers"
              location="Atlanta, Georgia"
              position="Entrepreneur"
              time="June 2018 - August 2021"
              description="CEO of high-end sneaker, streetwear, and technology resell company."
              description2="Created connections with other business owners in the Atlanta area while maintaining positive customer interactions through meetups and online sales."
            />
          </li>
          <li className="w-experience-1">
            <ECard
              name="Panera Bread"
              location="Johns Creek, Georgia"
              position="Associate"
              time="November 2020 - May 2021"
              description="Monitored food preparation for hundreds of daily customers while communicating with kitchen staff to uphold health and safety standards for the highest quality meals."
            />
          </li>
        </ul>
      </div>
      <h4 className="volunteer">My volunteer experience.</h4>
      <div className="volunteer-container">
        <ul className="experiences">
          <li className="v-experience-3">
            <ECard
              name="MGOCSM"
              location="Lilburn, Georgia"
              position="Parish Member"
              time="August 2020 - Present"
              description="Mar Gregorios Orthodox Christian Student Movement - Managed a team of 10 members and oversaw public relation work along to recruit new members along with networking with different parish priests to spread the word."
              description2="Communicated with other local orthodox churches in the Greater Atlanta area to setup monthly bible studies and meetings."
              description3="Organized fundraising events, coordinated bible studies, retreats, and camps to help the youth bolster their spiritual relationship."
              description4="Set up and formatted spreadsheets using Microsoft Excel for members to use to keep track of donations for events."
            />
          </li>
          <li className="v-experience-2">
            <ECard
              name="Sunrise Senior Living"
              location="Johns Creek, Georgia"
              position="Activity Volunteer"
              time="August 2019 - March 2020"
              description="Assisted senior citizens during weekly board game sessions by distributing game boards, prizes, and snacks. Assisted in cleanup and sanitization of all game pieces and boards after use."
            />
          </li>
          <li className="v-experience-1">
            <ECard
              name="Northside Hospital"
              location="Cumming, Georgia"
              position="PACU Volunteer"
              time="May 2019 - August 2019"
              description="Post-Anesthesia Care Unit Volunteer – Responsible for assisting the staff in the PACU department while they are prepping patients and assisting patients as they awake from surgery/procedures."
              description2="Duties included - Assisting with making beds and setting up prep area for patients; stocking bedside cabinets with patient care items; running errands to the lab; transporting patients to other departments of the Hospital when needed; assembling patient chart packets and organizing files; assisting with pushing beds and carts, greeting patients, and performing general office/clerical needs."
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Experience;
