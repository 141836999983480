import React, { useEffect } from "react";
import "./projects.css";
import Card from "../card/card";
import { gsap, Power4 } from "gsap";

function Projects() {
  const tl = gsap.timeline();

  useEffect(() => {
    tl.from(".mini-container", {
      y: "-30%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
    });
    tl.from(
      ".card-container",
      {
        y: "30%",
        opacity: 0,
        duration: 1,
        ease: Power4.easeOut,
      },
      "-=0.5"
    );
    tl.from(
      ".coming-soon",
      {
        y: "-30%",
        opacity: 0,
        duration: 1,
        ease: Power4.easeOut,
      },
      "-=0.5"
    );
  });

  return (
    <div className="projects-container">
      <div className="mini-container">
        <h1 className="header-name">Projects</h1>
        <h4> Some of my personal projects.</h4>
      </div>
      <div className="card-container">
        <Card
          name="Game Of Fifteen"
          date="October 2022"
          tools="C"
          description="Simple implementation of the classic Game of Fifteen in C."
          url="/gameof15"
        />
        <Card
          name="This Website"
          date="May 2022 - June 2022"
          tools="React.js, SCSS, HTML"
          description="My first time creating a website from scratch using HTML, CSS and JavaScript. At the time of writing the website has been ported to React.js"
          url="/mywebsite"
        />
        <Card
          name="iTunes Gallery"
          date="April 2022 - May 2022"
          tools="Java, JavaFX 11, Maven"
          description="Developed a GUI application using JavaFX 11 that displays a gallery of unique image URI’s based on the results
      of a search query to the iTunes Search API."
          url="/itunesgallery"
        />
        <Card
          name="Crypto Price Monitor"
          date="November 2021"
          tools="Node.js, Axios"
          description="Created a cryptocurrency price monitor using Node.js and the Axios HTTP library that communicates with the CoinGecko API to retrieve price information about a requested cryptocurrency.  
      "
          url="/cryptomonitor"
        />
      </div>
      <h2 className="coming-soon">More Coming Soon!</h2>
    </div>
  );
}

export default Projects;
