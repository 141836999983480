import React from "react";
import "./ecard.css";

function ECard(props) {
  return (
    <div className="container">
      <div className="header-container">
        <div className="name-location">
          <h2 className="name">{props.name}</h2>
          <h5 className="location">{props.location}</h5>
        </div>
        <div className="position-time">
          <h2 className="position">{props.position}</h2>
          <h5 className="time">{props.time}</h5>
        </div>
      </div>
      <div className="descriptions">
        {props.description ? <p>{"• " + props.description}</p> : ""}
        {props.description2 ? <p>{"• " + props.description2}</p> : ""}
        {props.description3 ? <p>{"• " + props.description3}</p> : ""}
        {props.description4 ? <p>{"• " + props.description4}</p> : ""}
        {props.description5 ? <p>{"• " + props.description5}</p> : ""}
        {props.description6 ? <p>{"• " + props.description6}</p> : ""}
        {props.description7 ? <p>{"• " + props.description7}</p> : ""}
        {props.description8 ? <p>{"• " + props.description8}</p> : ""}
      </div>
    </div>
  );
}

export default ECard;
