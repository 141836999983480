import React from "react";
import Hero from "../hero/hero";
import Contact from "../contact/contact";

function Main() {
  return (
    <div>
      <Hero />
      <Contact />
    </div>
  );
}

export default Main;
