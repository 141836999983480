import React, { Component } from "react";
import TagCloud from "react-tag-cloud";
import randomColor from "randomcolor";
import "./cloud.css";

const styles = {
  large: {
    fontSize: 22,
    fontWeight: "bold",
  },
  small: {
    fontSize: 14,
  },
};

class Cloud extends React.Component {
  componentDidMount() {
    setInterval(() => {
      this.forceUpdate();
    }, 3000);
  }

  render() {
    return (
      <div className="outer-container">
        <div className="inner-container">
          <TagCloud
            className="tag-cloud"
            style={{
              fontFamily: "Poppins",
              fontSize: 30,
              padding: 5,
              color: () =>
                randomColor({
                  hue: "blue",
                }),
            }}
          >
            <div style={styles.large}>Java</div>
            <div style={styles.large}>Python</div>
            <div style={styles.large}>JavaScript</div>
            <div style={styles.large}>TypeScript</div>
            <div style={styles.large}>HTML</div>
            <div style={styles.large}>CSS</div>
            <div style={styles.large}>SCSS</div>
            <div style={styles.large}>Unix</div>
            <div style={styles.large}>Emacs</div>
            <div style={styles.large}>Git</div>
            <div style={styles.small}>SQL</div>
            <div style={styles.large}>Node.js</div>
            <div style={styles.large}>React.js</div>
            <div style={styles.large}>Angular.js</div>
            <div style={styles.small}>Express.js</div>
            <div style={styles.small}>Firebase</div>
            <div style={styles.small}>Maven</div>
            <div style={styles.small}>Excel</div>
            <div style={styles.small}>Electron.js</div>
            <div style={styles.small}>Next.js</div>
            <div style={styles.small}>Latex</div>
          </TagCloud>
        </div>
      </div>
    );
  }
}

export default Cloud;
