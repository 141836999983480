import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./hero.css";
import { motion } from "framer-motion";

function Hero() {
  const [time] = useState(new Date().getHours());
  const [message, setMessage] = useState("Hi!");

  useEffect(() => {
    if (time >= 12 && time < 18) {
      setMessage("Good Afternoon!");
    } else if (time >= 18) {
      setMessage("Good Evening!");
    } else {
      setMessage("Good Morning!");
    }
  }, []);

  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        mass: 0.4,
        damping: 8,
        when: "beforeChildren",
        staggerChildren: 0.4,
      },
    },
  };

  return (
    <div className="hero-container">
      <motion.div
        className="name-wave"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <h1 className="time-message" id="time-bm">
          {message}
        </h1>
        <img className="wave" src="/images/wave-icon.svg" alt="wave" />
      </motion.div>
      <h2 className="introduction">I'm Rohan Mathew.</h2>
      <div className="desc-container">
        <p className="about-desc">
          Hello! I'm a senior at the Georgia Institute of Technology pursuing a
          Bachelor's in <span>Computer Science</span> with threads of
          <span> Intelligence and People</span>.
        </p>

        <p className="secondary-desc">
          Currently, I am developing my skills in <span>Java</span> and have
          prior experience with <span>Python</span> and
          <span> JavaScript</span> (namely <span>Node.js</span>).
        </p>
        <p className="third-desc">
          Outside of CS, I enjoy watching Formula 1, lifting weights, and
          playing basketball or football with friends.
        </p>
      </div>
      <div className="more-about-container">
        <Link to="/about" className="more-about-me">
          More about me
        </Link>
      </div>
    </div>
  );
}

export default Hero;
