import React, { useEffect } from "react";
import "./footer.css";
import { gsap, Power4 } from "gsap";

function Footer() {
  const tl = gsap.timeline();

  useEffect(() => {
    tl.from(".footer-container", {
      y: "-30%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
    });
  }, "-=2.5");

  return (
    <footer>
      <div className="footer-container">
        <small>
          Made by Rohan Mathew • <span className="version">v3.0.3.5</span> •{" "}
          {new Date().getFullYear()}
        </small>
      </div>
    </footer>
  );
}

export default Footer;
