import React from "react";
import ProjectPage from "./projectpage";

class ItunesGallery extends React.Component {
  code = `try {
    // getting the response and sending the request.
    String term = URLEncoder.encode(inputParse(tf), StandardCharsets.UTF_8);
    String media = URLEncoder.encode(getComboValue(), StandardCharsets.UTF_8);
    String limit = URLEncoder.encode("200", StandardCharsets.UTF_8);
    String query = String.format("?term=%s&media=%s&limit=%s", term, media, limit);
    this.uri = itunes + query;
    HttpRequest request = HttpRequest.newBuilder()
        .uri(URI.create(uri))
        .build();
    HttpResponse<String> response = HTTP_CLIENT
        .send(request, BodyHandlers.ofString());
    // ensure the request is valid.
    if (response.statusCode() != 200) {
        throw new IOException(response.toString());
    } // if
    String jsonString = response.body();
    jsonString.trim();
    ItunesResponse itunesResponse = GSON.fromJson(jsonString, ItunesResponse.class);
    // creating urls array and prints the urls used and how many there are.
    urls = parseItunesResponse(itunesResponse);
    checkUrls();
    // this throws IAE which creates the alert box.
    lessThan21Error(checkUrls());
    System.out.println(uri);
    System.out.println("# of total urls found " + urls.length);
    System.out.println("# of distinct urls found " + distinctUrls.size());
    updateProgress();
    updateTiles(uri);
    // sets buttons to normal state to continue searches/play.
    getImages.setDisable(false);
    play.setDisable(false);
} catch (IOException | InterruptedException | IllegalArgumentException e) {
    getImages.setDisable(false);
    play.setDisable(false);
    checkDefault();
    // creates and displays the exception box when called/
    Platform.runLater(() -> {
        label.setText("Last attempt to get images failed...");
        Alert alert = new Alert(Alert.AlertType.ERROR,
            "URI: " + uri +  " \nException: " + e.toString(),
            ButtonType.OK);
        alert.showAndWait();
        play.setText("Play");
    });
} // try`;

  render() {
    return (
      <div>
        <ProjectPage
          name="iTunes Gallery"
          overview="my iTunesGallery Project"
          description="This project was my first time creating a GUI software in Java. To complete this project I had to learn a lot about JavaFX 11, Threading, HTTP Requests and JSON parsing."
          description2="The video below is a quick demonstration of this project!"
          vid="/videos/iTunesGallery.mp4"
          img="/images/galleryapp.png"
          codeBlock={this.code}
          description5="Using HTTP Requests we first make a call to the iTunes API and check to filter out distinct URI's and make sure there are more than 20. From there we set the URI to the Image object which we then pass into the ImageView object. For every Image object that is passed in the progress bar will increment by 5% until it fills up. Using threads, we are able to increment the progress bar while the GUI remains functional and the URI's are being collected. The images will appear when all the ImageView objects have a new Image object from the API call."
          description6="The play feature takes a random tile from the grid and replaces it with another image from the collected URI's."
          afterImgDesc="The code above is a small snippet of the logic used to make a request to the iTunes API. Using HTTP requests, any query so long as 20 distinct URI's are found for the query will return the image corresponding to that URI. Some URI's can have the same artwork associated to it (the songs are in the same album) which will still be displayed as only distinct URI's are checked. "
        />
      </div>
    );
  }
}
export default ItunesGallery;
