import React, { Component } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }
  handleClick = () => {
    this.setState({
      clicked: !this.state.clicked,
    });
  };

  render() {
    return (
      <div className="header">
        <div className="mini-container">
          <motion.div
            className="name"
            whileHover={{
              scale: 1.2,
              originX: 0,
            }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <Link to="/" className="header-name">
              Rohan <span>Mathew</span>
            </Link>
          </motion.div>

          <nav>
            <ul id="big-container">
              <motion.li
                whileHover={{
                  scale: 1.2,
                }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Link to="/projects">/projects</Link>
              </motion.li>
              <motion.li
                whileHover={{
                  scale: 1.2,
                }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Link to="/about">/about</Link>
              </motion.li>
              <motion.li
                whileHover={{
                  scale: 1.2,
                }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Link to="/experience">/experience</Link>
              </motion.li>
            </ul>
          </nav>
          <img
            id="menu-bar"
            src="/images/menu-bar.png"
            alt="Menu Bar"
            onClick={this.handleClick}
          />
        </div>
        <div className="navbar">
          <nav className={this.state.clicked ? "side-bar active" : "side-bar"}>
            <img
              id="close"
              src="/images/menu-close.png"
              alt="Close Menu Bar"
              onClick={this.handleClick}
            />
            <ul className="bar">
              <li>
                <Link onClick={this.handleClick} to="/projects">
                  /projects
                </Link>
              </li>
              <li>
                <Link onClick={this.handleClick} to="/about">
                  /about
                </Link>
              </li>
              <li>
                <Link onClick={this.handleClick} to="/experience">
                  /experience
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <hr className="divider" />
      </div>
    );
  }
}

export default Header;
