import React, { useEffect } from "react";
import "./about.css";
import Cloud from "../cloud/cloud";
import { gsap, Power4 } from "gsap";

function About() {
  const tl = gsap.timeline();

  useEffect(() => {
    tl.from(".mini-container", {
      y: "-30%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
    });
    tl.from(
      ".first-desc",
      {
        y: "-30%",
        opacity: 0,
        duration: 1.5,
        ease: Power4.easeOut,
      },
      "-=0.8"
    );
    tl.from(
      ".second-desc",
      {
        y: "-30%",
        opacity: 0,
        duration: 1.5,
        ease: Power4.easeOut,
      },
      "-=0.8"
    );
    tl.from(
      ".last-desc",
      {
        y: "-30%",
        opacity: 0,
        duration: 1.5,
        ease: Power4.easeOut,
      },
      "-=0.8"
    );
    tl.from(
      ".tech-stack",
      {
        y: "30%",
        opacity: 0,
        duration: 1.5,
        ease: Power4.easeOut,
      },
      "-=0.8"
    );
  });

  return (
    <div className="about-container">
      <div className="mini-container">
        <h1 className="header-name">About Me</h1>
        <h4>Get to know a little bit about me!</h4>
      </div>
      <div className="main-container">
        <div className="about-desc">
          <p className="first-desc">
            Hi! I'm Rohan Mathew, a{" "} 
            ~{(
              (Date.now() -
                new Date("3 October 2002 00:07:00 EST").getTime()) /
              (1 * 1000 * 60 * 60 * 24 * 365)
            ).toPrecision(6)} 
            {" "}year old Computer Science student at the Georgia Institute of Technology. I
            was first exposed to programming when my friend asked me to test out
            a bot to help him purchase limited sneakers from retailers. With his
            bot, I was able to get a pair of{" "}
            <a
              className="yeezy"
              href="https://stockx.com/adidas-yeezy-boost-350-v2-butter"
              target="_blank"
              rel="noopener noreferrer"
            >
              "Butter" Yeezy's
            </a>{" "}
            which I was then able to sell for a profit. I was fascinated at the
            idea of using technology to automate the purchase process of
            sneakers and began trying to create my own scripts on websites like{" "}
            <a
              className="supreme"
              href="https://www.supremenewyork.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Supreme
            </a>{" "}
            and{" "}
            <a
              className="nike"
              href="https://www.nike.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nike
            </a>
            .
          </p>
          <p className="second-desc">
            {" "}
            I started off learning <span>Python</span> but i'm currently most
            comfortable with <span>Java</span>. I enjoy working with{" "}
            <span>JavaScript</span> and plan to start mastering{" "}
            <span>Python</span> in the near future.{" "}
          </p>
          <p className="last-desc">
            I am always looking to meet new people. If you are interested in my
            skillset or just want to chat with me, feel free to reach out via
            social media or email!{" "}
          </p>
        </div>
        <div className="tech-stack">
          <h2>Technology Stack</h2>
          <p>
            Below are the technologies that I know currently. The larger the
            item, the more comfortable I am with the technology!
          </p>
          <Cloud />
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default About;
