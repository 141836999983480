import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";

class Blocks extends React.Component {
  render() {
    return (
      <CodeBlock
        text={this.props.code}
        language={"javascript"}
        theme={dracula}
        wrapLongLines="true"
      />
    );
  }
}

export default Blocks;
